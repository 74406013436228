import {
    runValidation,
    validRegistrationNumber,
} from '@moller/design-system/utilities/validation';
import { useEffect, useState } from 'react';
import { isAnnataAdapter } from './_api/adapterUtils';
import { useGetRegisteredVehicles } from './_api/http/vehicle';
import { useAppConfig } from './lib/config/configContext';
import { useFastTrack } from './lib/useFastTrack';
import { useAdditionalServices } from './sections/additional-services/AdditionalServicesSection';
import { useChooseDealer } from './sections/choose-dealer/ChooseDealerSection';
import { useChooseTimeSlot } from './sections/choose-timeslot/ChooseTimeslotSection';
import { useChooseVehicle } from './sections/choose-vehicle/ChooseVehicleSection';
import { useConfirmBooking } from './sections/confirm-booking/ConfirmBookingSection';
import {
    StandardServicesData,
    useStandardServices,
} from './sections/standard-services/useStandardServices';
import { useTransportServices } from './sections/transport-services/TransportServicesSection';

export type SetIsBookingRequest = {
    services: StandardServicesData['selectedServices'];
    isEmailRequest: boolean;
};

function determineIsBookingRequest({
    services,
    isEmailRequest,
}: SetIsBookingRequest) {
    const isAnnataBooking = services.some((x) => isAnnataAdapter(x?.adapterId));
    const hasSelectedBookingRequestService = services.some(
        (x) => x?.serviceCategory !== 'RecommendedService'
    );
    return (
        isAnnataBooking && (hasSelectedBookingRequestService || isEmailRequest)
    );
}

if (import.meta.vitest) {
    const { it, expect } = import.meta.vitest;
    const annataService: StandardServicesData['selectedServices'][number] = {
        serviceId: '1',
        adapterId: 'annata-adapter',
        serviceCategory: 'Tire',
        serviceType: 'Standard',
        dealerSpecificInformation: [],
        name: 'Tire service',
    };
    const recommendedService: StandardServicesData['selectedServices'][number] =
        {
            serviceId: '1',
            adapterId: 'annata-adapter',
            serviceCategory: 'RecommendedService',
            name: 'Recommended service',
            serviceType: 'Standard',
            dealerSpecificInformation: [],
        };
    const mnetService: StandardServicesData['selectedServices'][number] = {
        serviceId: '1',
        adapterId: 'mnet-adapter',
        name: 'Eu test',
        serviceCategory: 'EuTest',
        serviceType: 'Standard',
        dealerSpecificInformation: [],
    };
    it.concurrent(
        'determineIsBookingRequest - standard service with annata dealer',
        () => {
            const actual = determineIsBookingRequest({
                services: [annataService],
                isEmailRequest: false,
            });
            expect(actual).toBe(true);
        }
    );
    it.concurrent(
        'determineIsBookingRequest - recommended service with annata dealer',
        () => {
            const actual = determineIsBookingRequest({
                services: [recommendedService],
                isEmailRequest: false,
            });
            expect(actual).toBe(false);
        }
    );
    it.concurrent(
        'determineIsBookingRequest - recommended service with email request',
        () => {
            const actual = determineIsBookingRequest({
                services: [recommendedService],
                isEmailRequest: true,
            });
            expect(actual).toBe(true);
        }
    );
    it.concurrent(
        'determineIsBookingRequest - standard service with mnet dealer',
        () => {
            const actual = determineIsBookingRequest({
                services: [mnetService],
                isEmailRequest: false,
            });
            expect(actual).toBe(false);
        }
    );
    it.concurrent(
        'determineIsBookingRequest - mnet dealer with email request',
        () => {
            const actual = determineIsBookingRequest({
                services: [mnetService],
                isEmailRequest: true,
            });
            expect(actual).toBe(false);
        }
    );
}

export function useBookingState(
    setInterruptUpdateTimestamp: React.Dispatch<React.SetStateAction<number>>
) {
    const config = useAppConfig();
    const { registrationNumber, vin } = config.bookingParameters;
    const vehicles = useGetRegisteredVehicles();
    const vehicleFromVin = vehicles.data?.find(
        (x) => x.vehicleIdentificationNumber === vin
    );
    const fastTrackType = useFastTrack();
    const isFastTrack = !!fastTrackType.fastTrack;

    const [isBookingRequest, setIsBookingRequestState] = useState(false);
    const setIsBookingRequest = (args: SetIsBookingRequest) => {
        setIsBookingRequestState(determineIsBookingRequest(args));
    };

    const validation = runValidation(
        registrationNumber ?? '',
        undefined,
        validRegistrationNumber
    );

    const chooseVehicle = useChooseVehicle({
        initialState:
            validation.isValid && validation.value
                ? {
                      viewMode: 'done',
                      data: { registrationNumber: validation.value },
                  }
                : { viewMode: 'edit' },
    });

    const setVehicle = chooseVehicle.props.setSectionState;
    useEffect(() => {
        if (vehicleFromVin) {
            setVehicle({
                viewMode: 'done',
                data: { registrationNumber: vehicleFromVin.plateNumber },
            });
        }
    }, [setVehicle, vehicleFromVin]);

    const chooseDealer = useChooseDealer({
        initialState: {
            viewMode: 'hidden',
        },
        dependencies: { chooseVehicle: chooseVehicle.result },
    });

    const standardServices = useStandardServices({
        initialState: {
            viewMode: 'hidden',
        },
        dependencies: {
            chooseVehicle: chooseVehicle.result,
            chooseDealer: chooseDealer.result,
        },
        setInterruptUpdateTimestamp: setInterruptUpdateTimestamp,
    });

    const chooseTimeSlot = useChooseTimeSlot({
        initialState: {
            viewMode: 'hidden',
        },
        dependencies: {
            chooseVehicle: chooseVehicle.result,
            chooseDealer: chooseDealer.result,
            standardServices: standardServices.result,
        },
        isBookingRequest,
    });

    const transportServices = useTransportServices({
        initialState: {
            viewMode: 'hidden',
        },
        dependencies: {
            chooseVehicle: chooseVehicle.result,
            chooseTimeSlot: chooseTimeSlot.result,
            chooseStandardServices: standardServices.result,
            chooseDealer: chooseDealer.result,
        },
        isFastTrack,
    });

    const additionalServices = useAdditionalServices({
        initialState: {
            viewMode: 'hidden',
        },
        dependencies: {
            chooseVehicle: chooseVehicle.result,
            chooseTimeSlot: chooseTimeSlot.result,
            transportServices: transportServices.result,
            chooseDealer: chooseDealer.result,
        },
        isFastTrack,
    });

    const confirmBooking = useConfirmBooking({
        initialState: {
            viewMode: 'edit',
        },
        dependencies: {
            chooseVehicle: chooseVehicle.result,
            chooseDealer: chooseDealer.result,
            standardServices: standardServices.result,
            chooseTimeSlot: chooseTimeSlot.result,
            transportServices: transportServices.result,
            additionalServices: additionalServices.result,
        },
    });

    return {
        chooseVehicle,
        chooseDealer,
        standardServices,
        chooseTimeSlot,
        additionalServices,
        transportServices,
        confirmBooking,
        isBookingRequest,
        setIsBookingRequest,
        isFastrack: !!fastTrackType.fastTrack,
    };
}
